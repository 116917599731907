import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import CardArchitectOffice from 'Components/CardArchitectOffice';
import CardArchitectProject from 'Components/CardArchitectProject';
import CardArchitecturePrize from 'Components/CardArchitecturePrize';
import CardBlog from 'Components/CardBlog';
import CardBlogPost from 'Components/CardBlogPost';
import CardCompetition from 'Components/CardCompetition';
import CardCompetitionEntry from 'Components/CardCompetitionEntry';
import CardCourse from 'Components/CardCourse';
import CardEvent from 'Components/CardEvent';
import CardLandscapeArchitect from 'Components/CardLandscapeArchitect';
import CardNews from 'Components/CardNews';
import CardPage from 'Components/CardPage';
import s from './ArticleList.module.scss';

const ArticleList = ({
    title = '',
    text = '',
    link = {},
    type = 'news',
    items = [],
    hideEmpty = true,
    isRekAI = false,
}) => {
    const {t} = useTranslation();

    if(hideEmpty && _.isEmpty(items)) {
        return null;
    }

    const cards = {
        blog: CardBlog,
        'blog-post': CardBlogPost,
        course: CardCourse,
        event: CardEvent,
        news: CardNews,
        page: CardPage,
        'architect-office': CardArchitectOffice,
        'architect-project': CardArchitectProject,
        'architecture-prize': CardArchitecturePrize,
        'competition': CardCompetition,
        'competition-entry': CardCompetitionEntry,
        'landscape-architect': CardLandscapeArchitect,
    };

    const defaultTitle = t([`articleList.title.${type}`, 'articleList.title.default']);
    const heading = !_.isEmpty(title) ? title : defaultTitle;

    return (
        <div className={s['ArticleList']}>
            <div className={s['ArticleList__Wrap']}>
                <div className={s['ArticleList__Header']}>
                    <h2 className={s['ArticleList__Title']}>{heading}</h2>

                    {!_.isEmpty(link) &&
                        <Link {...link} type={type} />
                    }

                    {text &&
                        <p className={s['ArticleList__Text']}>{text}</p>
                    }
                </div>
                <div className={s['ArticleList__List']}>
                    {items.map((item, index) => {
                        const cardType = _.get(item, 'type', type);
                        const Card = _.get(cards, cardType, CardNews);
                        return (
                            <div
                                key={index}
                                className={s['ArticleList__Item']}
                            >
                                <Card {...item} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

ArticleList.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.object,
    type: PropTypes.string,
    items: PropTypes.array,
    hideEmpty: PropTypes.bool,
};

const Link = ({url = '', title = '', target = '', type = 'news'}) => {
    const {t} = useTranslation();
    const text = !_.isEmpty(title) ? title : t([`articleList.link.${type}`, 'articleList.link.default']);
    return (
        <a className={s['ArticleList__Link']} href={url} target={target}>{text}</a>
    );
};

Link.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    type: PropTypes.string,
};

export default ArticleList;
