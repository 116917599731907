import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import {ErrorBoundary} from 'react-error-boundary';
import Script from 'next/script';
import LazyContainers from '../containers/LazyContainers';

const ErrorFallback = ({
    error = {},
    isDebug = false,
    ...restProps
}) => {
    // Handle failed lazy loading of a JS/CSS chunk.
    useEffect(() => {
        console.error(error);
    }, [error]);

    return (
        <div>
            <p>Something went wrong.</p>
            {isDebug &&
                <pre>{error?.message}</pre>
            }
        </div>
    );
};

ErrorFallback.propTypes = {
    error: PropTypes.object,
    isDebug: PropTypes.bool,
};

const AppContext = createContext({
    isSSR: false,
    isStorybook: false,
    isDebug: false,
    containerName: '',
});

const App = ({
    containerName = '',
    rekaiId = '',
    rekaiDebug = false,
    ...restProps
}) => {
    const isDebug = process?.env?.NODE_ENV === 'development';

    const [rekaiReady, setRekaiReady] = useState(false);
    const [rekaiAutocompleteReady, setRekaiAutocompleteReady] = useState(false);

    const Container = LazyContainers[containerName];
    if (!Container) {
        return null;
    }

    const settings = {
        isSSR: typeof window === 'undefined',
        isStorybook: false,
        isDebug,
        containerName,
        rekaiId,
        rekaiReady,
        setRekaiReady,
        rekaiAutocompleteReady,
        setRekaiAutocompleteReady,
    };

    return (
        <ErrorBoundary
            FallbackComponent={(fallbackProps) => (
                <ErrorFallback
                    {...fallbackProps}
                    isDebug={isDebug}
                />
            )}
        >
            <AppContext.Provider value={settings}>
                {rekaiId && (isDebug || rekaiDebug) && (
                    <Script
                        id="rekai-test-env"
                        strategy="beforeInteractive"
                        dangerouslySetInnerHTML={{
                            __html: `
                                document.cookie = 'rekblock=1';
                            `,
                        }}
                    />
                )}

                <Container
                    containerName={containerName}
                    {...restProps}
                />
            </AppContext.Provider>
        </ErrorBoundary>
    );
};

App.propTypes = {
    containerName: PropTypes.string,
};

export {AppContext};

export default App;
