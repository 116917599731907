const getPath = (str) => {
    if (!str) {
        return str;
    }
    if (typeof str === 'object' || str === null) return null;

    const parsed = new URL(str);
    const path = parsed.pathname;
    return path.startsWith('/') ? path.slice(1) : path;
};

const getRekAITreeFromUrls = (field) => {
    if (!field) {
        return null;
    }

    return Array.isArray(field) ? field.map(getPath).join(',') : getPath(field);
};

const getRekAIParams = ({ subtree = null, excludetree = null, ...restProps }) => {
    return {
        ...restProps,
        ...(subtree && { subtree: getRekAITreeFromUrls(subtree) }),
        ...(excludetree && { excludetree: getRekAITreeFromUrls(excludetree) }),
    };
};

export { getRekAITreeFromUrls, getRekAIParams };
