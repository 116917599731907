/* global isJest */
import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeBasicTags} from 'utils/html';
import Filter from './Filter';
import Tabs from './Tabs';
import Result from './Result';
import s from './Search.module.scss';

const Search = (props) => {
    const {t} = useTranslation();

    // Count is either an object for global search or number for specific search
    const {
        baseUrl,
        countApiUrl,
        type,
        input,
        title,
        showInput,
        inputName,
        currentFilter,
        showFilter,
        filters,
        tab,
        page,
        sort,
        showEmpty,
        extraArgs,
        isGlobalSearch,
        count,
        backendLoaded,
        rekaiParams,
        useRekaiAutocomplete,
    } = props;

    const defaultFilter = {
        ...currentFilter,
        [inputName]: input,
    };

    const defaultTab = _.isEmpty(tab) ? 'page' : tab;
    const [initial, setInitial] = useState(backendLoaded);
    const [loading, setLoading] = useState(!backendLoaded);
    const [currCount, setCurrCount] = useState(count);
    const [currParams, setCurrParams] = useState({
        paged: page,
        sort: sort,
        tab: defaultTab,
    });
    const [currFilter, setCurrFilter] = useState(defaultFilter);
    const [isEmpty, setIsEmpty] = useState(false);

    const currTab = _.get(currParams, 'tab', '');
    const currPage = _.get(currParams, 'paged', 1);
    const currSort = _.get(currParams, 'sort', 'relevance');
    const currInput = _.get(currFilter, inputName, '');

    const updateParams = (name, value) => {
        setCurrParams(state => ({...state, [name]: value}));
    };

    const getUrlParams = () => {
        const tabParam = _.isEmpty(currTab) ? {} : {tab: currTab};
        const sortParam = _.isEmpty(currSort) || currSort === 'relevance' ? {} : {sort: currSort};
        const pageParam = currPage > 1 ? {paged: currPage.toString()} : {};
        const searchParam = _.isEmpty(currInput) ? {} : {search: currInput};
        return {...currFilter, ...tabParam, ...searchParam, ...sortParam, ...pageParam};
    };

    const getUrl = (updatedParams = {}) => {
        const currentParams = getUrlParams();
        const params = {...currentParams, ...updatedParams};
        const query = createQuery(params);
        return `${baseUrl}${query}`;
    };

    // Map params for history and url changes
    const mapParams = (key, val) => {
        if(Array.isArray(val) && !_.isEmpty(val)) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(val.join(','))}`;
        } else if(!_.isEmpty(val)) {
            return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        } else {
            return '';
        }
    };

    const createQuery = (params) => {
        const q = Object.keys(params).map(k => mapParams(k, params[k]));
        const str = q.filter(v => !_.isEmpty(v)).join('&');
        return str !== '' ? `?${str}` : '';
    };

    const updateHistory = () => {

        // TODO: Fix difference between newState and default state when loading page, they should be same

        if(typeof(isJest) === 'undefined' || !isJest) {
            const newState = getUrlParams();
            // Make sure only to push state if it changes
            if(!_.isEqual(window.history.state, newState)) {
                const url = getUrl();
                window.history.pushState(newState, '', url);
            }
        }
    };

    useEffect(() => {
        // Update fields if changed from push state
        window.onpopstate = (e) => {
            const newInput = _.get(e, 'state.search', '');
            const defaultTab = type === 'global' ? 'page' : null;
            const newParams = {
                paged: _.get(e, 'state.paged', 1),
                sort: _.get(e, 'state.sort', 'relevance'),
                tab: _.get(e, 'state.tab', defaultTab),
            };
            if(!_.isEqual(newParams, currParams)) {
                setCurrParams(newParams);
            }
            if(!_.isEqual(newInput, currInput)) {
                setCurrFilter(state => ({...state, [inputName]: newInput}));
            }
        };
    });

    useEffect(() => {
        if(initial) {
            setInitial(false);
        }
    }, []);

    // currParams will change if input changes, so only watch that param
    useEffect(() => {
        // Prevent update history on page load
        if(!initial) {
            updateHistory();
        }
    }, [currParams]);

    const countQuery = createQuery({...currFilter, ...extraArgs});
    const countUrl = `${countApiUrl}${countQuery}`;

    const countLists = useCallback(() => {
        if(isGlobalSearch) {
            fetch(countUrl).then(res => new Promise(resolve => {
                resolve(res.json());
            })).then((res) => {
                setCurrCount(res);
            }, () => {
                setCurrCount({});
            });
        }
    }, [countUrl, isGlobalSearch]);
    useEffect(countLists, [countLists]);

    useEffect(() => {
        // If initial tab is empty, no specific tab has been selected
        // If there also is a search query, check current counter for which tab has results
        if(_.isEmpty(tab) && !_.isEmpty(currInput) && !_.isEmpty(currCount)) {
            // Skip global and use as default if all are empty
            const maxTab = _.maxBy(Object.entries(_.omit(currCount, 'global')), ([key, value]) => value)[0];
            const changeTab = currCount[maxTab] > 0 ? maxTab : 'global';
            if(currTab !== changeTab) {
                setCurrParams({...currParams, tab: changeTab});
            }
        }
    }, [currCount]);

    const headerTitle = title ? title : t([`search.title.${type}`, 'search.title.default']);
    const headerLabel = t([`search.label.${type}`, 'search.label.default']);
    const showFilterBlock = showFilter || showInput || !_.isEmpty(filters);

    // Possibility to hide component if completely empty
    if(!showFilterBlock && isEmpty && !showEmpty) {
        return null;
    }

    const convertFilterToObject = (filter) => {
        const arr = _.get(filter, 'options', []);
        return _.fromPairs(arr.map(({label, value}) => [value, label]));
    };

    // Pass specific filters as options that can be used in card
    const countyIdFilter = filters?.find(({name}) => name === 'county_id');
    const specializationFilter = filters?.find(({name}) => name === 'specialization');
    const options = {
        counties: convertFilterToObject(countyIdFilter),
        specializations: convertFilterToObject(specializationFilter),
    };

    const TitleTag = isGlobalSearch ? 'h1' : 'h2';

    const classes = classNames(
        [s['Search']],
        [s[`Search--${_.upperFirst(type)}`]],
        {[s['Search--Filter']]: showFilterBlock},
    );

    return (
        <div className={classes}>
            <div className={s['Search__Wrap']}>
                <div className={s['Search__Header']}>
                    <TitleTag className={s['Search__Title']}>
                        {headerLabel &&
                            <span className={s['Search__Label']}>
                                {headerLabel}
                                <span className="sr-only">: </span>
                            </span>
                        }
                        <span
                            dangerouslySetInnerHTML={{
                                __html: sanitizeBasicTags(headerTitle),
                            }}
                        />
                    </TitleTag>

                    {showFilterBlock &&
                        <div className={s['Search__Filter']}>
                            <Filter
                                id="search-filter"
                                type={type}
                                showInput={showInput}
                                inputName={inputName}
                                filters={filters}
                                defaultFilter={defaultFilter}
                                currFilter={currFilter}
                                setFilter={setCurrFilter}
                                updateParams={updateParams}
                                useRekaiAutocomplete={useRekaiAutocomplete}
                                rekaiParams={rekaiParams}
                            />
                        </div>
                    }
                </div>

                {isGlobalSearch &&
                    <div className={s['Search__Tabs']}>
                        <Tabs
                            currTab={currTab}
                            currCount={currCount}
                            loading={loading}
                            onClickHandler={(val) => setCurrParams({
                                page: 1,
                                sort: 'relevance',
                                tab: val,
                            })}
                        />
                    </div>
                }

                <Result
                    {...props}
                    options={options}
                    currCount={currCount}
                    currFilter={currFilter}
                    currTab={currTab}
                    currPage={currPage}
                    currSort={currSort}
                    setCurrPage={(val) => updateParams('paged', val)}
                    setCurrSort={(val) => updateParams('sort', val)}
                    loading={loading}
                    backendLoaded={backendLoaded}
                    setLoading={setLoading}
                    setIsEmpty={(empty) => setIsEmpty(empty)}
                    getUrl={(newParams) => getUrl(newParams)}
                />
            </div>
        </div>
    );
};

Search.propTypes = {
    apiUrl: PropTypes.string,
    countApiUrl: PropTypes.string,
    baseUrl: PropTypes.string,
    input: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    limit: PropTypes.number,
    tab: PropTypes.string,
    page: PropTypes.number,
    sort: PropTypes.string,
    list: PropTypes.array,
    showInput: PropTypes.bool,
    showFilter: PropTypes.bool,
    inputName: PropTypes.string,
    currentFilter: PropTypes.object,
    filters: PropTypes.array,
    count: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
    ]),
    backendLoaded: PropTypes.bool,
    showEmpty: PropTypes.bool,
    extraArgs: PropTypes.object,
    isGlobalSearch: PropTypes.bool,
    archiveLinks: PropTypes.object,
    rekaiParams: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    useRekaiAutocomplete: PropTypes.bool,
};

Search.defaultProps = {
    apiUrl: '',
    countApiUrl: '',
    baseUrl: '',
    input: '',
    title: '',
    type: '',
    limit: 6,
    tab: null,
    page: 1,
    sort: null,
    list: [],
    count: 0,
    backendLoaded: true,
    showInput: true,
    showFilter: true,
    inputName: 'search',
    currentFilter: {},
    filters: [],
    showEmpty: true,
    extraArgs: {},
    isGlobalSearch: false,
    archiveLinks: null,
    rekaiParams: {},
    useRekaiAutocomplete: false,
};

export default Search;
