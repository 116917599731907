import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import {sanitizeText} from 'utils/html';
import ReactSelect from 'Components/ReactSelect';
import s from './Select.module.scss';

const Select = ({
    id = '',
    type = '',
    name = '',
    title = '',
    options = [],
    defaultFilter = {},
    currFilter = {},
    updateFilter = null,
}) => {
    const {t, i18n} = useTranslation();
    const isMulti = type === 'multiselect';

    const selected = currFilter[name] ? currFilter[name] : (isMulti ? [] : '');
    let placeholder = title ? title : '';
    if(i18n.exists(`search.filter.${name}.title`)) {
        placeholder = t(`search.filter.${name}.title`);
    }
    const parsedOptions = options.map((option) => {
        const {value, label} = option;
        let optLabel = label ? label : '';
        if(optLabel === '' && i18n.exists(`search.filter.${name}.options.${value}`)) {
            optLabel = t(`search.filter.${name}.options.${value}`);
        }
        if(optLabel === '') {
            optLabel = value;
        }
        return {...option, label: optLabel};
    });
    const selectOptions = [...parsedOptions].filter(({value}) => {
        return !isMulti || !selected.includes(value);
    });

    let defaultOption = _.get(defaultFilter, name) || null;
    if(defaultOption !== null) {
        defaultOption = selectOptions.find(({value}) => {
            return value === defaultOption;
        });
    }

    let currOption = selected;
    if(!_.isEmpty(currOption)) {
        if(isMulti) {
            currOption = [...parsedOptions].filter(({value}) => currOption.includes(value));
        } else {
            currOption = [...parsedOptions].find(({value}) => value === currOption);
        }
    }

    // Change option to handle hierarchy items if they exist
    const getOptionLabel = ({label, isRoot}) => {
        const rootLabel = <strong
            dangerouslySetInnerHTML={{__html: sanitizeText(label)}}
        />;
        const subLabel = <span
            dangerouslySetInnerHTML={{__html: sanitizeText(label)}}
        />;
        return isRoot ? rootLabel : subLabel;
    };

    const changeHandler = (selectedOption) => {
        if(isMulti) {
            if(selectedOption === null) {
                updateFilter(name, []);
            } else {
                const newFilter = _.uniq(selectedOption.map(({value}) => value));
                updateFilter(name, newFilter);
            }
        } else {
            if(selectedOption === null) {
                updateFilter(name, '');
            } else {
                updateFilter(name, selectedOption.value);
            }
        }
    };

    const identifier = `${id}-${name}`;

    return (
        <div className={s['SearchSelect']}>
            <ReactSelect
                id={identifier}
                inputId={`${identifier}-input`}
                instanceId={`${identifier}-instance`}
                // menuIsOpen={true} // Use for debugging
                options={selectOptions}
                defaultFilter={defaultOption}
                name={name}
                placeholder={placeholder}
                onChange={changeHandler}
                isClearable={true}
                value={currOption}
                isOptionSelected={option => {
                    return isMulti ? selected.includes(option.value) : selected === option.value;
                }}
                noOptionsMessage={() => t('search.select.noOptionsMessage')}
                getOptionLabel={getOptionLabel}
                isMulti={isMulti}
            />
        </div>
    );
};

Select.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    options: PropTypes.array,
    defaultFilter: PropTypes.object,
    currFilter: PropTypes.object,
    updateFilter: PropTypes.func,
};

export default Select;
