/* global dataLayer */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { AppContext } from 'Layouts/App';
import s from './Field.module.scss';
import { getRekAITreeFromUrls, getRekAIParams } from '../../../utils/rekai';

// For testing locally
const _rekaiProjectId = process.env.NEXT_PUBLIC_REKAI_PROJECT_ID || undefined;

const Field = ({
    type = '',
    input = '',
    name = 'search',
    id = 'search-filter-form',
    setInput = () => {},
    triggerSubmit = () => {},
    rekaiParams = {},
    useRekaiAutocomplete = false,
}) => {
    const {
        isDebug,
        isStorybook,
        rekaiId,
        setRekaiReady,
        rekaiAutocompleteReady,
        setRekaiAutocompleteReady,
    } = useContext(AppContext);

    const { t } = useTranslation();

    const [value, setValue] = useState(input);
    const [selection, setSelection] = useState('');

    const placeholder = t([`search.field.placeholder.${type}`, 'search.field.placeholder.default']);
    const label = t([`search.field.label.${type}`, 'search.field.label.default']);
    const fieldId = `${id}-input`;

    const params = getRekAIParams({
        ...rekaiParams,
        addcontent: true,
        ...((isStorybook || isDebug) && {advanced_mockdata: true}),
    });

    useEffect(() => {
        setInput(value);
    }, [value]);

    useEffect(() => {
        if (rekaiAutocompleteReady && rekaiId && useRekaiAutocomplete) {
            initAutocomplete();
        }
    }, [rekaiAutocompleteReady]);

    const initAutocomplete = () => {
        const rekAutocomplete = rekai_autocomplete(`#${fieldId}`, {
            ...(_rekaiProjectId && {projectid: _rekaiProjectId}),
            debug: isDebug,
            params: params,
        });
        rekAutocomplete.on('rekai_autocomplete:selected', function(event, suggestion) {
            event.preventDefault();
            rekAutocomplete.autocomplete.close();
            setSelection(suggestion.title);
        });
    };

    useEffect(() => {
        if(selection) {
            if(typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    'event': 'rekai_auto_search',
                    'rekai_search_term': value,
                    'rekai_search_proposal': selection,
                });
            }

            setValue(selection);
            setInput(selection);
            if(isStorybook) {
                console.log('Rek AI autocomplete selected', value, selection);
            } else {
                triggerSubmit(selection);
            }
        }
    }, [selection]);

    const classes = classNames(
        [s['SearchField']],
        [s[`SearchField--${_.upperFirst(type)}`]],
        {[s['SearchField--HasValue']]: !_.isEmpty(input)},
        {[s['SearchField--RekAI']]: rekaiId && useRekaiAutocomplete},
    );

    return (
        <>
            {rekaiId && useRekaiAutocomplete && (
                <>
                    <Script
                        id="rekai-init"
                        src={`https://static.rekai.se/${rekaiId}.js`}
                        onReady={() => {
                            setRekaiReady(true);
                        }}
                    />
                    <Script
                        id="rekai-autocomplete-init"
                        src="https://static.rekai.se/addon/v3/rekai_autocomplete.min.js"
                        strategy="lazyOnload"
                        onReady={() => {
                            setRekaiAutocompleteReady(true);
                        }}
                    />
                </>
            )}
            <div className={classes}>
                <input
                    className={s['SearchField__Input']}
                    id={fieldId}
                    name={name}
                    value={input}
                    placeholder={placeholder}
                    onChange={e => setValue(e.target.value)}
                />
                <label htmlFor={fieldId} className={s['SearchField__Label']}>
                    {label}
                </label>
            </div>
        </>
    );
};

Field.propTypes = {
    type: PropTypes.string,
    input: PropTypes.string,
    setInput: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    triggerSubmit: PropTypes.func,
    rekaiParams: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    useRekaiAutocomplete: PropTypes.bool,
};

export default Field;
