import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowIcon from 'Assets/svg/arrowRight.svg';
import SearchForm from 'Components/SearchForm';
import s from './Menu.module.scss';

const Menu = ({ label, items, modifier, searchForm }) => {
    const classes = classNames(
        [s['Menu']],
        {[s[`Menu--${modifier}`]]: modifier},
    );

    return (
        <nav
            className={classes}
            aria-label={label}
        >
            <ul className={s['Menu__List']}>
                {items.map((item, index) => 
                    <Item {...item} key={index} searchForm={searchForm} />
                )}
            </ul>
        </nav>
    );
};

Menu.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    modifier: PropTypes.string,
};

Menu.defaultProps = {
    label: '',
    items: [],
    modifier: '',
};

const Item = ({ searchForm = {}, ...restProps }) => {
    const {
        title,
        url,
        children,
        isActive,
        modifier,
    } = restProps;

    const [isHover, setHover] = useState(false);

    const navItemClass = classNames(
        [s['Menu__Item']],
        {[s['Menu__Item--HasChildren']]: children.length},
        {[s['Menu__Item--Active']]: isActive},
        {[s['Menu__Item--Hover']]: isHover},
        {[s['Menu__Item--Blur']]: !isHover},
        {[s[`Menu__Item--${modifier}`]]: modifier},
    );
    
    return (
        <li 
            className={navItemClass}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {modifier === 'Search' ? (
                <div className={s['Menu__Search']}>
                    <SearchForm {...restProps} id="menu-search" type="menu" {...searchForm} />
                </div>
            ) : (
                <Fragment>
                    <a className={s['Menu__Link']} href={url}>
                        {title}
                        {children.length > 0 && <ArrowIcon />}
                    </a>
                    {children.length > 0 &&
                        <ul className={s['Menu__Sub']}>
                            {children.map(({ url, title }, index) =>
                                <li className={s['Menu__SubItem']} key={index}>
                                    <a href={url} className={s['Menu__SubLink']}>{title}</a>
                                </li>
                            )}
                        </ul>
                    }
                </Fragment>
            )}
        </li>
    );
};

Item.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.array,
    isActive: PropTypes.bool,
    modifier: PropTypes.string,
    searchForm: PropTypes.object,
};

Item.defaultProps = {
    title: '',
    url: '',
    children: [],
    isActive: false,
    modifier: '',
};

export default Menu;
