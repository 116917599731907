/* global dataLayer */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { AppContext } from 'Layouts/App';
import { getRekAITreeFromUrls, getRekAIParams } from '../../utils/rekai';
import s from './SearchForm.module.scss';

// For testing locally
const _rekaiProjectId = process.env.NEXT_PUBLIC_REKAI_PROJECT_ID || undefined;

const SearchForm = ({
    url = '',
    title = '',
    id = 'search-form',
    type = '',
    rekaiParams = {},
    useRekaiAutocomplete = true,
}) => {
    const {
        isStorybook,
        isDebug,
        rekaiId,
        setRekaiReady,
        rekaiAutocompleteReady,
        setRekaiAutocompleteReady,
    } = useContext(AppContext);

    const {t} = useTranslation();

    const formRef = useRef();

    const [value, setValue] = useState('');
    const [selection, setSelection] = useState('');
    const [isFocus, setFocus] = useState(false);

    const defaultPlaceholder = t([`searchForm.placeholder.${type}`, 'searchForm.placeholder.default']);
    const defaultLabel = t([`searchForm.label.${type}`, 'searchForm.label.default']);
    const submitText = t([`searchForm.submit.${type}`, 'searchForm.submit.default']);
    const placeholder = !_.isEmpty(title) ? title : defaultPlaceholder;
    const label = !_.isEmpty(title) ? title : defaultLabel;
    const fieldId = `${id}-input`;

    const params = getRekAIParams({
        ...rekaiParams,
        addcontent: true,
        ...((isStorybook || isDebug) && {advanced_mockdata: true}),
    });

    useEffect(() => {
        if (rekaiAutocompleteReady && rekaiId && useRekaiAutocomplete) {
            initAutocomplete();
        }
    }, [rekaiAutocompleteReady]);

    const initAutocomplete = () => {
        const rekAutocomplete = rekai_autocomplete(`#${fieldId}`, {
            ...(_rekaiProjectId && {projectid: _rekaiProjectId}),
            debug: isDebug,
            params: params,
        });
        rekAutocomplete.on('rekai_autocomplete:selected', function(event, suggestion) {
            event.preventDefault();
            rekAutocomplete.autocomplete.close();
            setSelection(suggestion.title);
        });
    };

    useEffect(() => {
        if(selection) {
            if(typeof dataLayer !== 'undefined') {
                dataLayer.push({
                    'event': 'rekai_auto_search',
                    'rekai_search_term': value,
                    'rekai_search_proposal': selection,
                });
            }

            if(isStorybook) {
                console.log('Rek AI autocomplete selected', value, selection);
            } else if(formRef && formRef.current) {
                formRef.current.submit();
            }
        }
    }, [selection]);

    const classes = classNames(
        [s['SearchForm']],
        {[s['SearchForm--HasValue']]: !_.isEmpty(value)},
        {[s['SearchForm--Focus']]: isFocus},
        {[s[`SearchForm--${_.upperFirst(type)}`]]: type},
        {[s['SearchForm--RekAI']]: rekaiId && useRekaiAutocomplete},
    );

    return (
        <>
            {rekaiId && useRekaiAutocomplete && (
                <>
                    <Script
                        id="rekai-init"
                        src={`https://static.rekai.se/${rekaiId}.js`}
                        onReady={() => {
                            setRekaiReady(true);
                        }}
                    />
                    <Script
                        id="rekai-autocomplete-init"
                        src="https://static.rekai.se/addon/v3/rekai_autocomplete.min.js"
                        strategy="lazyOnload"
                        onReady={() => {
                            setRekaiAutocompleteReady(true);
                        }}
                    />
                </>
            )}
            <form
                className={classes}
                action={url}
                id={id}
                ref={formRef}
                onSubmit={(e) => {
                    if(isStorybook) {
                        e.preventDefault();
                    }
                }}
            >
                <div className={s['SearchForm__Field']}>
                    <label className={s['SearchForm__Label']} htmlFor={fieldId}>
                        <span className={s['SearchForm__LabelText']}>{label}</span>
                    </label>
                    <input
                        className={s['SearchForm__Input']}
                        type="text"
                        name="search"
                        id={fieldId}
                        placeholder={placeholder}
                        onChange={(e) => setValue(e.target.value)}
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                    />
                </div>
                <button
                    className={s['SearchForm__Submit']}
                    type="submit"
                >{submitText}</button>
            </form>
        </>
    );
};

SearchForm.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
    rekaiParams: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    useRekaiAutocomplete: PropTypes.bool,
};

export default SearchForm;
